export const CAL_FEATURES = [
  {
    title: 'All-in-one',
    text: 'Stay effortlessly organized with touchscreen display for all your calendar events, chores, dinner plans, and tasks',
  },
  {
    title: 'Calendars unite',
    text: 'Auto-sync and merge every calendar from multiple personal devices for total clarity',
  },
  {
    title: 'Anytime, anywhere planning',
    text: 'Easily update your schedule on-the-go with the free Skylight mobile app',
  },
  {
    title: 'Good habits',
    text: 'Use our Chore Chart to turn daily duties into triumphs and cultivate positive habits and routines',
  },
  {
    title: 'Dinner decided',
    text: 'Display your family’s dinner plans and end the daily debate',
  },
  {
    title: 'Stay on track',
    text: 'Curate custom lists that encompass everything your family needs to remember, do, or purchase',
  },
]

export const CAL_MAX_FEATURES = [
  {
    title: 'All-in-one',
    text: 'Stay effortlessly organized with the touchscreen display for all your calendar events, chores, meal plans, and tasks',
  },
  {
    title: 'Calendars unite',
    text: 'Auto-sync and merge every calendar from multiple personal devices for absolute clarity',
  },
  {
    title: 'Anytime, anywhere planning',
    text: 'Easily update your schedule on-the-go with the free Skylight mobile app',
  },
  {
    title: 'Good habits',
    text: 'Use our Chore Chart to turn daily duties into triumphs and cultivate positive habits and routines',
  },
  {
    title: 'Dinner decided',
    text: 'Display your family’s dinner plans and end the daily debate',
  },
  {
    title: 'Stay on track',
    text: 'Curate custom lists that encompass everything your family needs to remember, do, or purchase',
  },
]
