export const CAL_TESTIMONIALS = [
  {
    text: `“Skylight Calendar has reduced my son's anxiety so much that he was discharged by his psychologist...it has changed our life."`,
    name: 'Mary M.',
    household: 'Mom of 1,',
    location: 'Perkasie, PA',
  },
  {
    text: `“This has been a game changer! It has allowed our family a one-stop shop for all things calendar and keeps my husband and I on the same page which is huge!”`,
    name: 'Lucy G.',
    household: 'Mom of 2,',
    location: 'Brooklyn, NY',
  },
  {
    text: `“I LOVE that my brain is no longer the long-term storage plan for our family's busy schedule, and my kids have never been so motivated by a chore chart.”`,
    name: 'Emily B.',
    household: 'Mom of 3,',
    location: 'Los Angeles, CA',
  },
  {
    text: `“Skylight has brought clarity and structure to our busy schedules. It's a great communication tool for our whole family to get on the same page.”`,
    name: 'Mark S.',
    household: 'Dad of 2,',
    location: 'Dallas, TX',
  },
  {
    text: `“I love how it takes so much off of my mental load and how easy it is for my kids to use.”`,
    name: 'Krista W.',
    household: 'Mom of 3,',
    location: 'Phoenix, AZ',
  },
]
