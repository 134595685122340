import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Trans } from 'gatsby-plugin-react-i18next'
import RedesignCalendarCarousel from '../RedesignCalendarCarousel'
import { Section } from '../CalendarTestimonials/styles'
import { Header } from '../RedesignCalendarCarousel/styles'

const RedesignCalendarTestimonials = () => {
  const {
    forwardImg,
    backImg,
    customerOneImg,
    customerTwoImg,
    customerThreeImg,
    customerFourImg,
    customerFiveImg,
  } = useStaticQuery(graphql`
    query RedesignCalendarTestimonialsQuery {
      customerOneImg: file(relativePath: { eq: "calendar/customer-mary.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      customerTwoImg: file(relativePath: { eq: "calendar/customer-krista.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      customerThreeImg: file(relativePath: { eq: "calendar/customer-lucy.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      customerFourImg: file(relativePath: { eq: "calendar/customer-emily.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      customerFiveImg: file(relativePath: { eq: "calendar/customer-mark.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      backImg: file(relativePath: { eq: "calendar/arrow-left.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      forwardImg: file(relativePath: { eq: "calendar/arrow-right.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  const customerImages = [
    customerOneImg,
    customerThreeImg,
    customerFourImg,
    customerFiveImg,
    customerTwoImg,
  ]

  return (
    <Section>
      <Header>
        <Trans>Loved by families like yours</Trans>
      </Header>
      <RedesignCalendarCarousel forwardImg={forwardImg} backImg={backImg} images={customerImages} />
    </Section>
  )
}

export default RedesignCalendarTestimonials
