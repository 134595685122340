import React from 'react'
import { Trans } from 'gatsby-plugin-react-i18next'
import Green from '../../images/calendar/checkbox-green.svg'
import Red from '../../images/calendar/checkbox-red.svg'

import {
  MatrixContainer,
  Section,
  Row,
  Cell,
  CheckCell,
  HeaderCell,
  Title,
  Subheader,
  Checkmark,
  FeatureName,
  Table,
  HeaderCellText,
} from './styles'
import { COMPETITOR_COMPARISON } from './constants'

const CalendarMatrix = () => (
  <Section>
    <MatrixContainer>
      <Subheader>The choice is easy</Subheader>
      <Table>
        <thead>
          <Row last>
            <HeaderCell />
            <HeaderCellText>
              <Title>Skylight Calendar</Title>
            </HeaderCellText>
            <HeaderCellText>
              <Title>Them</Title>
            </HeaderCellText>
          </Row>
        </thead>
        <tbody>
          {COMPETITOR_COMPARISON.map((feature, index) => (
            <Row key={index} white={index % 2 === 0}>
              <Cell>
                <FeatureName>
                  <Trans>{feature.text}</Trans>
                </FeatureName>
              </Cell>
              <CheckCell blue>
                <Checkmark type="image/svg+xml" data={Green}>
                  svg-animation
                </Checkmark>
              </CheckCell>
              <CheckCell check borderRadius>
                <Checkmark type="image/svg+xml" data={feature.them ? Green : Red}>
                  svg-animation
                </Checkmark>
              </CheckCell>
            </Row>
          ))}
        </tbody>
      </Table>
    </MatrixContainer>
  </Section>
)

export default CalendarMatrix
